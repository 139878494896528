import { Outlet } from '@remix-run/react'

import { GenericErrorSection } from '~/components/boundaries/GenericErrorBoundary'
import { useRouteErrorWithReporter } from '~/hooks/useRouteErrorWithReporter'

const AcquitionFlowWrapper = () => <Outlet />

export function ErrorBoundary() {
  const error = useRouteErrorWithReporter()

  if (process.env.NODE_ENV !== 'production') console.error(error)

  return <GenericErrorSection />
}

export default AcquitionFlowWrapper
